import React from 'react'
import MainContent from "../components/MainContent"
import Services from "../components/Services"
import ContactUs from "../components/ContactUs"
import Committee from "../components/Committee"
import MyGallery from "../components/MyGallery"
import MemberRegistration from "../components/MemberRegistration"
import LiveStream from './LiveStream'
import AllEvents from "./AllEvents"
import DataDemo from "./DataDemo"
import { Router, Routes, Route, BrowserRouter } from 'react-router-dom'
import Success from './Success'
import MemberRegistrationForm from './MemberRegistrationForm'
import Dashboard from './Dashboard'

const MainRouter = () => {
  return (
    <div>
      
            <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/contacts" element={<ContactUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/committee" element={<Committee />} />
            <Route path="/gallery" element={<MyGallery />} />
            <Route path="/events" element={<AllEvents />} />
            <Route path="/members" element={<MemberRegistrationForm />} />
            <Route path="/success" element={<Success />} />
            <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
            </div>
  )
}

export default MainRouter
