
import { Suspense, lazy } from 'react'
import { useRef, useEffect, useState } from 'react';
import Mycarousel from '../components/Mycarousel';
import React from 'react';
import Footer from './MyFooter';
import MyGallery from '../components/MyGallery';
import Committee from '../components/Committee';
import MyMap from '../components/MyMap';
import NewsFeed from '../components/NewsFeed';
import MyNavigator from '../components/MyNavigator';
import '../components/MainContent.css';
import HomeVideo from './HomeVideo';
import ImageGrid from '../components/ImageGrid';
import FileUpload from '../components/FileUpload'
import RegistrationForm from '../components/RegistrationForm'
import Abcd from './Abcd'
import ReactDOM from 'react-dom';
import LiveStream from './LiveStream';
import Practice from './Practice';
import Example from './Example';
import MemberRegistration from './MemberRegistration';
import Success from '../components/Success';
import DisplayMembers from "../components/DisplayMembers"
import Cookies from '../components/Cookies';
import LoginModal from './Trials';
import Crud from "../components/Crud"
import DisplayImages from './DisplayImages';


const Card = lazy(() => import('../components/Details'));
const Services = lazy(() => import('../components/Services'));

/*Scroll Component*/

const ScrollComponent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.3 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <div ref={ref} className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}>
      {children}
    </div>
  );
};
/*Scroll Component*/


const MainContent = () => {
   return (
    <Suspense fallback={<div><h4 className='primary'></h4></div>}>
    <div>
    
    <Mycarousel />
    <ScrollComponent>
    <Card/>
    </ScrollComponent>

    <ScrollComponent>
    <Services/>
    </ScrollComponent>

    <ScrollComponent>
    <NewsFeed />
    </ScrollComponent>

    <ScrollComponent>
      <HomeVideo/>
      </ScrollComponent>
<MyMap/>
      <ScrollComponent>
    
    </ScrollComponent>
    <Footer/>
                </div>
    </Suspense>
  

  )
}

export default MainContent