// src/components/Gallery.js
import React, { useState, Component} from 'react';
import '../components/ImageGallery.css';
import {SlideshowLightbox} from 'lightbox.js-react'

import Modal from 'react-modal';

import img1 from '../images/iftar.jpg'
import img2 from '../images/onam.jpg'
import img3 from '../images/town.jpg'

import a from '../images/a.jpg'
import b from '../images/b.jpg'
import c from '../images/c.jpg'

import d from '../images/d.jpg'
import e from '../images/e.jpg'
import f from '../images/f.jpg'

import g from '../images/g.jpg'
import h from '../images/h.jpg'
import i from '../images/i.jpg'

const images = [
  img1, // replace with your image URLs
  img2,
  img3,a,b,c,d,e,f,g,h,i

];

const ImageGallery = () => {
 
  return (
    <div>
      
      <div className='div-container'>
        
      <SlideshowLightbox className='sb-container' showThumbnails={true}>
   
   
        {images.map((image, index) => (
          
                 <img className='sky-img' key={index} src={image} width={240} height={117}/>  
            
        ))}
         </SlideshowLightbox> 
        
</div>

      
   
   

  
     
       
    </div>
  );
};

export default ImageGallery;
