import {React,useEffect} from 'react'
import {Card,Button, Container} from 'react-bootstrap';
import '../components/Services.css';
import education from '../images/education.jpg'
import business from '../images/business.jpg'
import healthcare from '../images/healthcare.jpg'
import events from '../images/events.jpg'
import social from '../images/social.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Animation only happens once
    });
  }, []);
  return (
    <div data-aos="fade-up-left">
       <div className='bg-success'>
      <h5>WE EXPLORE...</h5>
      </div>
        <div className="card-container">
        
        <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={social} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Social Welfare</Card.Title>
        <Card.Text>
        Empower vulnerable communities by providing essential resources, promoting equity, and enhancing overall quality of life.
        </Card.Text>
        <Button variant="primary">More..</Button>
      </Card.Body>
    </Card>

        <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={events} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Events</Card.Title>
        <Card.Text>
        Celebrate diversity through music, art, and traditions, fostering community spirit and showcasing rich cultural heritage.
        </Card.Text>
        <Button variant="primary">More..</Button>
      </Card.Body>
    </Card>

     <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={education} width={90} height={160} />
      <Card.Body>
        <Card.Title>Education</Card.Title>
        <Card.Text>
        Foster learning and skill development, enhancing knowledge, creativity, and personal growth in individuals of all ages and awarding the topers
              </Card.Text>
        <Button variant="primary">More..</Button>
      </Card.Body>
    </Card>


    <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={business} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Business</Card.Title>
        <Card.Text>
        Activities involve innovation, market research, and strategic planning to create and grow new business ventures effectively.
        </Card.Text>
        <Button variant="primary">More..</Button>
      </Card.Body>
    </Card>
    </div>
    <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
    </div>
  )
}

export default Services